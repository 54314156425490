<template>
  <Preloader v-if="isInfoLoading" />
  <div class="student-year" v-else>
    <p class="student-year__info">
      Все документы необходимо загружать с персонального компьютера или ноутбука
      во избежание технических неполадок
    </p>
    <DocsComponent
      :type="'education'"
      :project="true"
      :current-project="currentProject"
      :is_disabled="isDisabled || isBtnDisabled"
      :category_id="currentCategoryId || currentCategoryData?.info_category.id"
      :nomination_id="
        type === 'individual'
          ? individualNominationValue
          : granprixNominationValue
      "
      @docs-changed="docsChanged"
      @up-docs="(value) => (educationDocs = value)"
    />
    <div class="student-year__btn" v-if="!isDisabled">
      <Button @click="saveInfo" :loading="isBtnLoading">
        Сохранить изменения
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import moment from "moment";
import Preloader from "@/components/Blocks/Preloader";
import DocsComponent from "@/components/Blocks/DocsComponent";
export default {
  name: "StudentYearIndividualPerformance",
  components: { DocsComponent, Preloader, Button },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  props: ["currentProject", "type"],
  data() {
    return {
      isBtnLoading: false,
      isFormValidate: false,
      isBtnDisabled: false,
      educationDocs: [],
      errors: [],
      formData: {
        document_student_billet: "",
        document_student_record_book_main_page: "",
        document_student_record_book_session_pages_one: "",
        document_student_record_book_session_pages_two: "",
        document_student_record_book_session_pages_three: "",
        document_student_record_book_session_pages_four: "",
        document_student_record_book_session_pages_five: "",
        document_reference: "",
        document_higher_education: "",
        document_certificate_absence_academic_debt: "",
      },
    };
  },
  watch: {
    async user() {
      await this.loadInfo();
      await this.setProjectEducationData();
    },
    docs: {
      handler: function () {
        Object.keys(this.formData).forEach((item) => {
          return (this.formData[item] = this.docs[item]);
        });
      },
      deep: true,
    },
    disabledFields: {
      handler: function () {
        if (this.type === "individual") {
          this.isFormValidate =
            this.disabledFields["isIndividualEducationFilled"];
        }
        if (this.type === "collective") {
          this.isFormValidate =
            this.disabledFields["isCollectiveEducationFilled"];
        }
        if (this.type === "granprix") {
          this.isFormValidate =
            this.disabledFields["isGranprixEducationFilled"];
        }
      },
      deep: true,
    },
  },
  computed: {
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },

    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    ...mapState("user", ["user", "disabledFields", "isInfoLoading"]),
    ...mapState([
      "currentCategoryData",
      "individualNominationValue",
      "granprixNominationValue",
      "docs",
      "statementId",
      "currentCategoryId",
      "storage",
      "isOldApplication",
    ]),
    ...mapGetters(["isAllDocsLabels", "socialDocs"]),
  },
  methods: {
    async setFakeUserData() {
      this.educationDocs.forEach((item) => {
        if (typeof this.formData[item.key_document] === "object")
          this.user.education.document_files[item.key_document].full =
            this.formData[item.key_document];
      });
    },
    loadInfo() {
      if (this.user.education) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user.education.document_files[item] &&
            this.user.education.document_files[item].thumb
          ) {
            return (this.formData[item] =
              this.user.education.document_files[item]);
          } else return (this.formData[item] = this.docs[item]);
        });
      }
      if (this.type === "individual") {
        this.isFormValidate =
          this.disabledFields["isIndividualEducationFilled"];
      }
      if (this.type === "collective") {
        this.isFormValidate =
          this.disabledFields["isCollectiveEducationFilled"];
      }
      if (this.type === "granprix") {
        this.isFormValidate = this.disabledFields["isGranprixEducationFilled"];
      }
    },
    async saveInfo() {
      if (!this.isFormValidate) return;
      if (this.errors?.length > 0) {
        this.$store.dispatch("validationErrors", {
          errors: this.errors,
          fieldsInfo: this.isAllDocsLabels,
        });
        return;
      }

      const needValidationDocsBefore = await this.$store.dispatch(
        "user/getNeedValidationDocuments"
      );

      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        if (
          this.user?.education?.document_files[item]?.thumb &&
          this.formData[item].thumb
        ) {
          this.formData[item] = "";
        } else {
          if (this.user.info_validation_documents.data.length > 0) {
            let infoDocument = this.user.info_validation_documents.data.find(
              (i) => {
                return i.key_document === item;
              }
            );
            if (infoDocument) {
              infoDocument.status = 0;
            }
          }
        }
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });

      this.isBtnLoading = true;
      this.isBtnDisabled = true;

      await createRequest(requestConfigs.POSTSaveEducation, {
        jsonPayload: payloadObject,
      })
        .then(async (response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            if (this.socialDocs.includes(item)) {
              this.user.social_documents.document_files[item] =
                response.data[item];
            } else
              this.user.education.document_files[item] = response.data[item];
          });
          Object.keys(this.formData).forEach((item) => {
            if (this.socialDocs.includes(item)) {
              this.formData[item] =
                this.user?.social_documents?.document_files[item];
            } else
              this.formData[item] = this.user?.education?.document_files[item];
            this.docs[item] = "";
          });
          // await this.setFakeUserData();
          await this.setProjectEducationData();

          const needValidationDocsResult = await this.$store.dispatch(
            "user/getNeedValidationDocuments"
          );
          if (needValidationDocsBefore > 0 && needValidationDocsResult === 0) {
            this.$store.commit("setCurrentPopup", {
              name: "PopupSendStatement",
              isShown: true,
              props: { id: this.currentCategoryData?.id },
            });
            return;
          }

          if (this.type === "individual") {
            if (this.disabledFields["isIndividualEducationFilled"]) {
              this.$router.push({ name: "StudentYearIndividualPortfolio" });
            }
          }
          if (this.type === "collective") {
            if (this.disabledFields["isCollectiveEducationFilled"]) {
              this.$router.push({ name: "StudentYearCollectivePortfolio" });
            }
          }
          if (this.type === "granprix") {
            if (this.disabledFields["isGranprixEducationFilled"]) {
              this.$router.push({ name: "StudentYearGrandPrixPortfolio" });
            }
          }
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnDisabled = false;
          this.isBtnLoading = false;
        });
    },
    async setProjectEducationData() {
      if (this.type === "individual") {
        this.$store.dispatch("user/checkProjectValidation", [
          this.educationDocs
            .filter((i) => {
              return i.is_required;
            })
            .map((item) => {
              return item.key_document;
            }),
          "isIndividualEducationFilled",
          "education",
        ]);
      }
      if (this.type === "collective") {
        this.$store.dispatch("user/checkProjectValidation", [
          this.educationDocs
            .filter((i) => {
              return i.is_required;
            })
            .map((item) => {
              return item.key_document;
            }),
          "isCollectiveEducationFilled",
          "education",
        ]);
      }
      if (this.type === "granprix") {
        this.$store.dispatch("user/checkProjectValidation", [
          this.educationDocs
            .filter((i) => {
              return i.is_required;
            })
            .map((item) => {
              return item.key_document;
            }),
          "isGranprixEducationFilled",
          "education",
        ]);
      }
    },
    docsChanged(value, errors) {
      this.isFormValidate = value;
      this.errors = errors;
    },
  },
  async mounted() {
    await this.loadInfo();
    await this.setProjectEducationData();
    Object.keys(this.formData).forEach((item) => {
      this.$store.commit("setDocument", ["", item]);
    });
  },
};
</script>

<style scoped></style>
